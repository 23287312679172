.dashB-box {
  text-align: center;
  border: 1px solid;
  border-radius: 10px;
  border-color: #e2e0e0;
  padding: 5px;
  background: #f3f3f3;
}
.dashB-box-length {
  font-size: 20px;
  margin-bottom: 10px;
  color: #9a1010;
  font-weight: bolder;
}
.dashB-box-text {
  font-weight: 500;
  margin-bottom: 5px !important;
}
